import gql from "graphql-tag";

export const GET_COMPANY_MEDICAL = gql`
  query($where: CompanyId) {
    getLpcCompanyMedical(where: $where) {
      name
      total
      id
      evaluated
      pending
      completed
      productId
    }
  }
`;

export const GET_LPC_PARAMETERS_GROUPS = gql`
  query($where: CompanyId) {
    getParameterGroupLpc(where: $where) {
      id
      name
    }
  }
`;

export const GET_LPC_PARAMETERS = gql`
  query($where: CompanyId) {
    getParametersLpc(where: $where) {
      id
      name
      unitOfMeasurement
      minimum
      maximum
      typeId
      date
      text
      yesOrNo
      positiveOrNegative
      result {
        positiveOrNegative
        yesOrNo
        text
        observation
        absolute
        percentage
      }
    }
  }
`;
export const GET_JOURNEY_MEDICAL = gql`
  query($where: CompanyId) {
    getLpcJourneyMedical(where: $where) {
      name
      id
      total
      evaluated
      pending
      completed
      productId
    }
  }
`;
export const COMPANY_LPC_LIST = gql`
  query {
    getCompanyList {
      id
      name
    }
  }
`;

export const COMPANY_LPC_JOURNEY_LIST = gql`
  query($where: CompanyId) {
    getJourneyList(where: $where) {
      id
      name
    }
  }
`;

export const GET_ARS = gql`
  query {
    getArs {
      value
      label
    }
  }
`;

export const PATIENT_BY_DOCUMENT = gql`
  query($where: PatientDocument) {
    getPatientbyDocument(where: $where) {
      id
      arsId
      document
      firstName
      lastName
      birthdate
      gender
      documentType
      alreadyExists
    }
  }
`;

export const GET_PENDING_CONSULTATION = gql`
  query($where: CompanyId) {
    getPendingConsultation(where: $where) {
      id
      name
      document
      age
      gender
    }
  }
`;

export const GET_EVALUATED_CONSULTATION = gql`
  query($where: CompanyId) {
    getEvaluatedConsultation(where: $where) {
      id
      name
      document
      age
      gender
      AttentionProgram
    }
  }
`;

export const UPDATE_STATE_LPC_CONSULTATION = gql`
  mutation($data: CompanyId) {
    updateLpcConsultation(data: $data) {
      success
      message
    }
  }
`;

export const CREATE_LPC_HISTORY = gql`
  mutation($data: lpcHistoryparameters) {
    createLpcAppointmentHistories(data: $data) {
      success
      message
    }
  }
`;

export const UPDATE_LPC_HISTORY = gql`
  mutation($data: lpcHistoryparameters, $where: CompanyId) {
    updateLpcC(data: $data, where: $where) {
      success
      message
    }
  }
`;

export const GET_ONE_LPC_CONSULTATION = gql`
  query($where: CompanyId) {
    getOneLpcConsultation(where: $where) {
      affiliate {
        id
        arsId
        document
        firstName
        lastName
        birthdate
        gender
        arsId
        documentType
        attentionProgram
        icdCode
      }
      eventId
    }
  }
`;

export const GET_LPC_ATTENTION_PROGRAMS = gql`
  query($where: CompanyId) {
    getLpcAttentionPrograms(where: $where) {
      value
      label
    }
  }
`;

export const GET_LPC_MEDICAL_STAFF = gql`
  query($where: CompanyId) {
    getLpcMedicalStaff(where: $where) {
      medicalStaffId
      specialityId
    }
  }
`;
