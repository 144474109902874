import React from "react";
import { useHistory } from "react-router";
import ReactTable from "react-table-v6";
import { Button } from "reactstrap";
import ReactNumberFormat from "react-number-format";
import moment from "moment";
import { sumFooterUtils } from "../../../../../../utils/utils";

export default function Table({ state }) {
  const history = useHistory();
  console.log(state);

  return (
    <div>
      <ReactTable
        data={state.registeredPatientsByPrograms}
        defaultPageSize={10}
        columns={[
          {
            Header: "Programa",
            accessor: "programName",
            Footer: () => <div className="text-center">Total</div>
          },
          {
            Header: "Activos",
            accessor: "actives",
            Cell: ({ original }) => (
              <ReactNumberFormat
                displayType="text"
                value={original.actives}
                thousandSeparator={true}
                renderText={value => (
                  <div className="text-right"> {value} </div>
                )}
              />
            ),
            Footer: ({ data }) => (
              <div className="text-center">
                {sumFooterUtils(data, "actives")}
              </div>
            )
          },
          {
            Header: "Controlados",
            accessor: "controlled.controlled",

            Cell: ({ original: { controlled } }) => (
              <ReactNumberFormat
                displayType="text"
                value={controlled.controlled}
                thousandSeparator={true}
                renderText={value => (
                  <div className="text-right"> {value} </div>
                )}
              />
            ),
            Footer: ({ data }) => {
              console.log(data);

              return (
                <div className="text-center">
                  {sumFooterUtils(data, "controlled.controlled")}
                </div>
              );
            }
          },
          {
            Header: "No Controlados",
            accessor: "controlled.unControlled",
            classname: "text-right",
            Cell: ({ original: { controlled } }) => (
              <ReactNumberFormat
                displayType="text"
                value={controlled.unControlled}
                thousandSeparator={true}
                renderText={value => (
                  <div className="text-right"> {value} </div>
                )}
              />
            ),
            Footer: ({ data }) => (
              <div className="text-center">
                {sumFooterUtils(data, "controlled.unControlled")}
              </div>
            )
          },
          {
            Header: "Cantidad de Citas",
            accessor: "count",
            className: "text-right",
            Footer: ({ data }) => {
              const total = data.reduce((acc, { count }) => acc + count, 0);
              return (
                <div>
                  <strong>{total}</strong>
                </div>
              );
            }
          },
          {
            Header: "Opciones",
            Cell: ({ original }) => (
              <Button
                onClick={() => {
                  history.push(
                    `/dashboard/performance-metrics-pyp/registered-patients/program/${
                      original.programId
                    }/startDate/${moment(state.startDate).format(
                      "YYYY-MM-DD"
                    )}/endDate/${moment(state.endDate).format("YYYY-MM-DD")}`
                  );
                }}
                color="success"
              >
                {" "}
                Ver detalle
              </Button>
            )
          }
        ]}
      />
    </div>
  );
}
