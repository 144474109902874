// dateStore.js
import create from "zustand";
export const useLpcStore = create((set, get) => ({
  document: "",
  parameter: [],
  parameters: [],
  observations: [],
  diagnostic: null,
  values: [],
  documentType: 0,
  affiliateId: 0,
  name: "",
  lastName: "",
  alreadyExists: false,
  birthdate: new Date(),
  programId: 0,
  icdCodeId: null,
  age: 0,
  gender: 0,
  eventId: 0,
  ars: null,
  setParameters2: newParameters => set({ parameters: newParameters }),
  // setEventId:(newEventId) => set({ eventId}),
  setObservation: (id, value) => {
    set(state => {
      const updatedObservations = state.observations.map(obs =>
        obs.id === id ? { ...obs, value } : obs
      );
      return { observations: updatedObservations };
    });
  },
  setParameterGroup: (id, type, newValue) => {
    set(state => {
      const updatedParameters = state.parameters.map(param =>
        param.id === id ? { ...param, value: newValue, typeId: type } : param
      );
      return { parameters: updatedParameters };
    });
  },
  setName: name => set({ name }),
  setAffiliateId: affiliateId => set({ affiliateId }),
  setLastName: lastName => set({ lastName }),
  setDocument: doc => set({ document: doc }),
  setBirthdate: birth => set({ birthdate: birth }),
  setDocumentType: type => set({ documentType: type }),
  setAge: age => set({ age }),
  setGender: gender => set({ gender }),
  setParameter: parameter => set({ parameter }),
  setArs: ars => set({ ars }),
  setProgramId: programId => set({ programId }),
  setIcdCodeId: icdCodeId => set({ icdCodeId }),
  setFields: fields => set(state => ({ ...state, ...fields })),
  clearFields: () =>
    set({
      document: "",
      documentType: 0,
      affiliateId: 0,
      name: "",
      lastName: "",
      birthdate: null, // Cambié null por una cadena vacía o puedes poner una fecha predeterminada si prefieres.
      age: 0,
      programId: null,
      gender: 0,
      ars: null
    }),
  clearFields2: () =>
    set({
      affiliateId: 0,
      name: "",
      lastName: "",
      birthdate: null,
      age: 0,
      gender: 0,
      ars: null
    }),
  setParameters: params => set(() => ({ parameters: params })),
  setValue: (id, typeId, value) =>
    set(state => {
      const existingIndex = state.values.findIndex(item => item.id === id);
      if (existingIndex !== -1) {
        const updatedValues = [...state.values];
        updatedValues[existingIndex] = {
          ...updatedValues[existingIndex],
          value
        };
        return { values: updatedValues };
      }
      return {
        values: [...state.values, { id, typeId, value }]
      };
    }),
  setObservation: (id, value) =>
    set(state => {
      const existingIndex = state.observations.findIndex(
        item => item.id === id
      );
      if (existingIndex !== -1) {
        const updatedObservations = [...state.observations];
        updatedObservations[existingIndex] = {
          ...updatedObservations[existingIndex],
          value
        };
        return { observations: updatedObservations };
      }
      return {
        observations: [...state.observations, { id, value }]
      };
    })
}));
