import { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { useHistory } from "react-router";
import moment from "moment";

const query = gql`
  query getRegisteredPatientsByPrograms($where: WhereGetRegisteredPatient) {
    registeredPatientsByPrograms: getRegisteredPatientsByPrograms(
      where: $where
    ) {
      programId
      programName
      actives
      count
      controlled {
        controlled
        unControlled
      }
    }
    programs: medicalAttentionPrograms {
      id
      name
    }
    registeredPatientByProgram: getRegisteredPatientByProgram(where: $where) {
      patientId
      activationDate
      controlled
      patientName
      pamId
      date
    }
    adherenceRateByPrograms: getAdherenceRateByPrograms(where: $where) {
      programId
      registered
      actives
      programName
      enrolled
      count
    }
  }
`;

const useActions = () => {
  const [state, setState] = useState({
    registeredPatientsByPrograms: [],
    patientProgramId: null,
    patientPatientid: null,
    registeredPatientByProgram: [],
    toView: "",
    startDate: moment({ day: 1 } /* "2021-10-01" */)
      // .subtract(1, "year")
      .toDate(),
    endDate: moment().toDate()
  });
  const [complete, setComplete] = useState(false);
  console.log(state);
  const { data, loading } = useQuery(query, {
    variables: {
      where: {
        programId: state.patientProgramId,
        startDate: moment(state.startDate).format("YYYY-MM-DD"),
        endDate: moment(state.endDate).format("YYYY-MM-DD")
      }
    },
    fetchPolicy: false
  });

  const onChange = element => {
    const name = element.currentTarget.name;
    const value = element.currentTarget.value;
    setState(state => ({ ...state, [name]: value }));
  };

  const history = useHistory();

  useEffect(() => {
    // console.log(complete)
    if (complete) {
      //setComplete(false);
      if (state.patientProgramId) {
        history.push(
          `/dashboard/performance-metrics/registered-patients/program/${state.patientProgramId}`
        );
      }
    }
  }, [complete, history, setComplete, state]);

  useEffect(() => {
    if (data && !loading) {
      setState(state => ({
        ...state,
        ...data
      }));
      setComplete(true);
    }
  }, [data, loading, setState, history]);

  return { state, setState, onChange, loading };
};

export default useActions;
