import React from "react";
import ReactTable from "react-table-v6";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import ReactNumberFormat from "react-number-format";
import moment from "moment";
import { sumFooterUtils } from "../../../../../../utils/utils";

export default function Table({ state }) {
  // const { data } = useQuery(QUERY);
  const history = useHistory();

  return (
    <div>
      <ReactTable
        data={state.adherenceRateByPrograms}
        defaultPageSize={10}
        columns={[
          {
            Header: "Tipo de Programa",
            accessor: "programName",
            Footer: () => <div className="text-center">Total</div>
          },
          {
            Header: "Enrolados",
            classname: "text-right",
            accessor: "enrolled",
            Cell: ({ original }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-right"> {value} </div>
                )}
                displayType="text"
                value={original.enrolled}
                thousandSeparator={true}
              />
            ),
            Footer: ({ data }) => (
              <div className="text-center">
                {sumFooterUtils(data, "enrolled")}
              </div>
            )
          },
          {
            Header: "Activos",
            accessor: "actives",
            classname: "text-right",
            Cell: ({ original }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-right"> {value} </div>
                )}
                displayType="text"
                value={original.actives}
                thousandSeparator={true}
              />
            ),
            Footer: ({ data }) => (
              <div className="text-center">
                {sumFooterUtils(data, "actives")}
              </div>
            )
          },
          {
            Header: "Tasa",

            Cell: ({ original: { actives, enrolled } }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-center"> {value} </div>
                )}
                suffix="%"
                fixedDecimalScale={true}
                decimalScale={2}
                displayType="text"
                value={(actives / enrolled) * 100}
                thousandSeparator={true}
              />
            ),
            Footer: ({ data }) => {
              const actives = sumFooterUtils(data, "actives");
              const enrolled = sumFooterUtils(data, "enrolled");

              console.log({ actives, enrolled });

              // return <div className="text-center">{Math.floor((actives / enrolled) * 100)}</div>
              return (
                <ReactNumberFormat
                  renderText={value => (
                    <div className="text-center"> {value} </div>
                  )}
                  suffix="%"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  displayType="text"
                  value={(actives / enrolled) * 100}
                  thousandSeparator={true}
                />
              );
            }
          },
          {
            Header: "Cantidad de Citas",
            accessor: "count",
            className: "text-right",
            Footer: ({ data }) => {
              const total = data.reduce((acc, { count }) => acc + count, 0);
              return (
                <div>
                  <strong>{total}</strong>
                </div>
              );
            }
          },
          {
            Header: "Opciones",
            Cell: ({ original }) => (
              <Button
                onClick={() => {
                  history.push(
                    `/dashboard/performance-metrics-pyp/adherence-rate/program/${
                      original.programId
                    }/startDate/${moment(state.startDate).format(
                      "YYYY-MM-DD"
                    )}/endDate/${moment(state.endDate).format("YYYY-MM-DD")}`
                  );
                }}
                color="success"
              >
                {" "}
                Ver detalle
              </Button>
            )
          }
        ]}
      />
    </div>
  );
}
