import { lazy } from "react";

const MainPage = lazy(() => import("./page"));
const Management = lazy(() => import("./page/managements"));

const routes = [
  {
    path: "/care/staff-substitute",
    component: MainPage,
    exact: true,
    name: "Cobertura de Médicos"
  },
  {
    path: "/care/staff-substitute/edit/:id",
    component: Management,
    exact: true,
    name: "Editar Cobertura de Médico"
  },
  {
    path: "/care/staff-substitute/create",
    component: Management,
    exact: true,
    name: "Crear Cobertura de Médico"
  }
];

export default routes;
